import {action, observable} from "mobx";
import {AffectSide, Gender, User} from "../types/UserDtoTypes";


class UserMob {
    @observable userInfo:User = {
        id: -1,
        surname: '',
        username: '',
        patronymic:'',
        affectedSide: {} as AffectSide,
        dateOfBirth: '',
        gender: {} as Gender,
        name: '',
        roles: []
    }

    @observable patientList: User[] = []

    @observable isAuth: boolean = false

    @observable authCred: {accessToken: string, refreshToken:string} = {accessToken:'', refreshToken:''}

    @action
    setIsAuth = (auth:boolean) => {
        this.isAuth = auth
    }

    @action
    setAuthCred = (authCred:{accessToken: string, refreshToken:string}) => {
        this.authCred = authCred
    }

    @action
    setUserInfo = (info: User) => {
        this.userInfo = info
    }

    @action
    addPatient = (patient: User) => {
        this.patientList.push(patient)
    }

    @action
    findPatientById = (patientId: number) => {
        let patient = this.patientList.find(elem => elem.id === patientId)
        return patient? patient : {} as User
    }

    @action
    setPatientList = (list:User[]) => {
        this.patientList = list
    }

    @action
    detachPatient = (patientId:number) => {
        let index = this.patientList.findIndex(elem => elem.id == patientId)

        if(index !== -1)
            this.patientList.splice(index,1)
    }

    @action
    clear = () => {
        this.userInfo = {
            id: -1,
            surname: '',
            username: '',
            patronymic:'',
            affectedSide: {} as AffectSide,
            dateOfBirth: '',
            gender: {} as Gender,
            name: '',
            roles: []
        }
        this.patientList = []
        this.isAuth = false
    }
}
export default UserMob