import React from 'react';
import './App.css';
import {useRoutes} from "./hooks/useRoutes";
import {
    BrowserRouter as Router
} from "react-router-dom"
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './context/CustomThemeProvider';

function App() {
    const routes = useRoutes()
    return (
        <ThemeProvider theme={theme}>
            <Router>
                {routes}
            </Router>
        </ThemeProvider>
    );
}

export default App;
