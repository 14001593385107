export const requestUrls = {
    auth: {
        url: "/auth",
        method: "POST"
    },
    refreshToken:{
        url:"/refresh",
        method: "POST"
    },
    getAuthUserDto: {
        url:"/api/users/user",
        method: "GET"
    },
    getUserMetaInfo:{
        url: "/api/users/user/metaInf",
        method: "GET"
    },
    getAttachPatients:{
        url: "/api/users/user/patients",
        method: "GET"
    },
    createNewAttachedPatient:{
        url: "/api/users/user/patient",
        method: "POST"
    },
    getAllGendersList:{
        url:"/api/genders",
        method: "GET"
    },
    getAllAffectedSidesList:{
        url:"/api/AffectedSides",
        method: "GET"
    },
    getAllDoctorsSessions:{
        url: (patientId: number) => `/api/sessions/doctor/patient/${patientId}`,
        method: "GET"
    },
    getAllExerciseBlocksWithExercises:{
        url: '/api/blocks/exercises',
        method: 'GET'
    },
    createNewSession:{
        url: "/api/sessions/session",
        method: "POST"
    },
    getSessionResults:{
        url: (sessionId:number) => `/api/sessions/session/${sessionId}/results`,
        method: "GET"
    },
    getSessionMeasurement:{
        url: (sessionId:number) => `/api/sessions/session/${sessionId}/measurements`,
        method: "GET"
    },
    getSessionDtoWithAccessCheck:{
        url: (sessionId: number) => `/api/sessions/session/${sessionId}`,
        method: "GET"
    },
    detachPatient:{
        url: (userId:number) => `/api/users/user/${userId}`,
        method: "DELETE"
    }
}