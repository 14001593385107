export const routes = {
    toHome: '/home',
    waiting: '/waiting',
    toLogin: '/login',
    toPatient: '/client/:patientId',
    goToPatient: (patientId: number) => `/client/${patientId}`,
    toAddSessionPage: '/addSession/:patientId',
    goToAddSessionPage: (patientId: number) => `/addSession/${patientId}`,
    toSessionStatisticPage: '/session/:statisticId/:patientId',
    goToSessionStatisticPage: (statisticId:number, patientId:number) => `/session/${statisticId}/${patientId}`,
}