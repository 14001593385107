import {useHttp} from "./useHttp";
import {useCookies} from "react-cookie";
import {Suspense, useCallback, useEffect} from "react";
import {useUser} from "../context/UserProvider";
import React from "react";
import {
    Redirect, Route, Switch
} from "react-router-dom"
import WaitingPage from "../pages/WaitingPage";
import {routes} from "../helpers/routes";
import {requestUrls} from "../helpers/urls";
import {tokenCookieName} from "../helpers/constant";
import {useObserver} from "mobx-react-lite";
import { addMilliseconds } from "date-fns";
const LoginPage = React.lazy(() => import('../pages/LoginPage'))
const HomePage = React.lazy(() => import('../pages/HomePage'))
const PatientPage = React.lazy(() => import('../pages/PatientPage'))
const AddSessionPage = React.lazy(() => import('../pages/AddSessionPage'))
const SessionStatisticPage = React.lazy(() => import('../pages/SessionStatisticPage'))

export const useRoutes = () => {
    const {loading, request, clearError} = useHttp()
    const [cookies, setCookie, removeCookie] = useCookies([tokenCookieName]);
    const user = useUser()

    const login = useCallback(async () => {
        try{
            const oldRefreshToken = cookies[tokenCookieName].refreshToken
            let {accessToken, refreshToken, refreshTokenExpirationTime} = await request(requestUrls.refreshToken.url, requestUrls.refreshToken.method,
                oldRefreshToken, {refreshToken: oldRefreshToken})
            setCookie(tokenCookieName,
                JSON.stringify({accessToken, refreshToken}),
                {expires : addMilliseconds(new Date(), refreshTokenExpirationTime),
                maxAge: refreshTokenExpirationTime / 1000})
            user.setIsAuth(true)
            user.setAuthCred({accessToken, refreshToken})
        }catch (e) {
            console.log(e)
            removeCookie(tokenCookieName)
            clearError()
            user.clear()
        }
    },[clearError, user, request])

    useEffect(() => {
        login()
    },[user, request, clearError])


    return  useObserver(() => (
                    <Switch>
                        <Suspense fallback={<WaitingPage/>}>
                            {loading ? <>
                                <Route path={routes.waiting}>
                                    <WaitingPage/>
                                </Route>
                                <Redirect to={routes.waiting}/>
                            </> : <>
                                {user.isAuth ? <>
                                    <Route exact path={routes.toHome}>
                                        <HomePage/>
                                    </Route>
                                    <Route path={routes.toPatient}>
                                        <PatientPage/>
                                    </Route>
                                    <Route path={routes.toAddSessionPage}>
                                        <AddSessionPage/>
                                    </Route>
                                    <Route path={routes.toSessionStatisticPage}>
                                        <SessionStatisticPage/>
                                    </Route>
                                    <Redirect to={routes.toHome}/>
                                </> : <>
                                    <Route exact path={routes.toLogin}>
                                        <LoginPage/>
                                    </Route>
                                    <Redirect to={routes.toLogin}/>
                                </>
                                }
                            </>}
                        </Suspense>
                    </Switch>
    ))
}